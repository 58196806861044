<template>
    <div class="container">
        <h1 class="mt-4">Quiz</h1>
        <hr>
        <p>
            Wenn du nach ein bisschen Abwechslung suchst, bist du hier genau richtig.
            Teste, was du schon alles zum Thema Computer und Informatik weißt.
        </p>
        <microsoft-form 
            :formid="quizId"
            height="280em"
        />
    </div>
</template>

<script>
import Formulare from "@/assets/json/formulare.json"
import MicrosoftForm from "@/components/MicrosoftForm"
export default {
    name: "Quiz",
    components:{ MicrosoftForm },
    data: () => ({
        quizId: Formulare.quiz
    })
}
</script>